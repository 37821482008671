body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.divider-subtle {
  border: none;
  border-top: 1px solid silver;
  border-top: 1px solid var(--color-border-divider-default-d3adp9);
}

/* footer */
.custom-main-footer {
  align-items: center;
  background-color: #0f1b2a;
  display: flex;
  gap: 10px;
  font-size: 14px;
  justify-content: space-between;
  left: 0;
  margin: 0;
  color: var(--color-text-home-header-secondary-vojy0v, #d1d5db);
  min-height: 40px;
  padding: 2px 40px;
  position: absolute;
  right: 0;
  z-index: 2000;
}
.custom-main-footer ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 10px;
  margin: 0;
  flex-wrap: wrap;
}
footer ul a {
  color: var(--color-text-home-header-secondary-vojy0v, #d1d5db);
  text-decoration: none;
}
.custom-main-footer li:not(:last-child) a {
  border-right: 1px solid var(--color-border-control-default-0fzxw0, #7d8998);
  padding-right: 10px;
}

[data-corner-style='rounded'] > * {
  border-radius: 18px !important;
}
.display-source div {
  padding-top: 0 !important;
}
.display-source span {
  font-size: small;
}

.display-source svg {
  /* stroke-width: 1px !important; */
  color: #929292;
}

p,
div {
  white-space: pre-line;
}
